<template>
  <div>
    <b-modal
      ref="product-stock-info"
      :title="`Nhập thông tin cho sản phẩm ${productItem.name}`"
      @ok="handleInfoInput"
      @close="hideModal"
      @hide="hideModal"
      ok-title="Lưu"
      cancel-title="Hủy"
    >
      <template v-if="productItem.productType === PRODUCT_TYPE.CHASSIS_ENGINE">
        <b-form-group id="input-group-1" label="Số khung" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="chassisNumber"
            type="text"
            required
            placeholder="Nhập số khung"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="input-group-1" label="Số máy" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="engineNumber"
            type="text"
            required
            placeholder="Nhập số máy"
          ></b-form-input>
        </b-form-group>
      </template>
      <template v-else>
        <b-form-group id="input-group-1" label="Số seri" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="serialNumber"
            type="text"
            required
            placeholder="Nhập số seri"
          ></b-form-input>
        </b-form-group>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { PRODUCT_TYPE_VEHICLE } from '@/utils/enum';

export default {
  props: ['productItem', 'mode'],
  data() {
    return {
      PRODUCT_TYPE: PRODUCT_TYPE_VEHICLE,
      chassisNumber: '',
      engineNumber: '',
      serialNumber: '',
      index: null,
    };
  },
  methods: {
    hideModal() {
      this.resetData();
      this.$refs['product-stock-info'].hide();
    },
    handleInfoInput() {
      const data = {
        proId: this.productItem.proId,
        index: this.index,
        chassisNumber: this.chassisNumber,
        engineNumber: this.engineNumber,
        serialNumber: this.serialNumber,
      };
      this.$emit('updateProductStockInfo', data);
    },
    showModal: function (productItem, index) {
      this.assignData(productItem, index);
      this.$refs['product-stock-info'].show();
    },
    assignData(productItem, index) {
      this.chassisNumber = productItem.chassisNumber;
      this.engineNumber = productItem.engineNumber;
      this.serialNumber = productItem.serialNumber;
      this.index = index;
    },
    resetData() {
      this.chassisNumber = '';
      this.engineNumber = '';
      this.serialNumber = '';
    },
  },
  created() {
    this.chassisNumber = this.productItem.chassisNumber;
    this.engineNumber = this.productItem.engineNumber;
    this.serialNumber = this.productItem.serialNumber;
  },
};
</script>
