import { PRODUCT_TYPE_VEHICLE } from '@/utils/enum';
import { v4 } from 'uuid';
import { makeToastFaile } from '@/utils/common';

const productAutosuggestMixin = {
  methods: {
    onSelectedProduct(option) {
      this.selectedClassProduct = option.item;
      this.searchProduct = option.item.name;
      let quantity = 0;
      if (
        [PRODUCT_TYPE_VEHICLE.DEFAULT].includes(this.selectedClassProduct.type)
      ) {
        quantity = 1;
        const index = this.listProductStock.findIndex(
          (item) => item.proId === this.selectedClassProduct.id,
        );

        if (index === -1) {
          let price = this.selectedClassProduct.importPrice;
          // @TODO: Review xem có cần PO
          // if (poProductMap[this.selectedClassProduct.productId]) {
          //   price = poProductMap[this.selectedClassProduct.productId];
          // }
          const pro = {
            id: v4(),
            name: this.selectedClassProduct.name,
            productCode: this.selectedClassProduct.code,
            barCode: this.selectedClassProduct.code,
            totalQuantityInStock:
              this.selectedClassProduct.quantityInStock != null
                ? this.selectedClassProduct.quantityInStock
                : 0,
            chassisNumber: '',
            engineNumber: '',
            proId: this.selectedClassProduct.id,
            price: price,
            quantity: quantity,
            discount: 0,
            totalPrice: quantity * price,
            productType: this.selectedClassProduct.type,
          };
          this.listProductStock.unshift(pro);
        } else {
          this.listProductStock[index].quantity =
            this.listProductStock[index].quantity + quantity;
        }
      } else if (
        [PRODUCT_TYPE_VEHICLE.CHASSIS_ENGINE].includes(
          this.selectedClassProduct.type,
        )
      ) {
        quantity = 1;
        let price = this.selectedClassProduct.importPrice;
        // if (poProductMap[this.selectedClassProduct.productId]) {
        //   price = poProductMap[this.selectedClassProduct.productId];
        // }
        const pro = {
          id: v4(),
          name: this.selectedClassProduct.name,
          productCode: this.selectedClassProduct.code,
          barCode: this.selectedClassProduct.code,
          totalQuantityInStock:
            this.selectedClassProduct.quantityInStock != null
              ? this.selectedClassProduct.quantityInStock
              : 0,
          chassisNumber: '',
          engineNumber: '',
          proId: this.selectedClassProduct.id,
          price: price,
          quantity: quantity,
          discount: 0,
          totalPrice: quantity * price,
          productType: this.selectedClassProduct.type,
        };
        this.listProductStock.unshift(pro);
      } else if (
        [PRODUCT_TYPE_VEHICLE.SERIES].includes(this.selectedClassProduct.type)
      ) {
        quantity = 1;
        let price = this.selectedClassProduct.importPrice;
        // if (poProductMap[this.selectedClassProduct.productId]) {
        //   price = poProductMap[this.selectedClassProduct.productId];
        // }
        const pro = {
          id: v4(),
          name: this.selectedClassProduct.name,
          productCode: this.selectedClassProduct.code,
          barCode: this.selectedClassProduct.code,
          totalQuantityInStock:
            this.selectedClassProduct.quantityInStock != null
              ? this.selectedClassProduct.quantityInStock
              : 0,
          serialNumber: '',
          proId: this.selectedClassProduct.id,
          price: price,
          quantity: quantity,
          discount: 0,
          totalPrice: quantity * price,
          productType: this.selectedClassProduct.type,
        };
        this.listProductStock.unshift(pro);
      } else if (
        [PRODUCT_TYPE_VEHICLE.SERVICE].includes(this.selectedClassProduct.type)
      ) {
        makeToastFaile('Không hỗ trợ nhập kho sản phẩm dịch vụ');
      }

      this.searchProduct = '';
    },
  },
};

export default productAutosuggestMixin;
