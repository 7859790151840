export class ProductStockPayload {
  /**
   * @param {Object} data
   * @param {String} data.type
   * @param {String} data.sourceWarehouseId
   * @param {String} data.destinationWarehouseId
   * @param {Number} data.cashAmount
   * @param {String} data.cashAccountCode
   * @param {Number} data.transferAmount
   * @param {String} data.transferAccountCode
   * @param {Number} data.totalAmount
   * @param {Number} data.discountAmount
   * @param {String} data.discountType
   * @param {String} data.providerId
   * @param {String} data.description
   * @param {String} data.vatSerial
   * @param {String} data.vatCode
   * @param {String} data.vatDate
   * @param {String | Number} data.vatAmount
   * @param {String | Number} data.vatType
   * @param {ProductStockDetailPayload[]} data.stockSlipDetails
   */
  constructor(data) {
    this.type = data.type;
    this.sourceWarehouseId = data.sourceWarehouseId;
    this.destinationWarehouseId = data.destinationWarehouseId;
    this.cashAmount = data.cashAmount;
    this.cashAccountCode = data.cashAccountCode;
    this.transferAmount = data.transferAmount;
    this.transferAccountCode = data.transferAccountCode;
    this.totalAmount = data.totalAmount;
    this.discountAmount = data.discountAmount;
    this.discountType = data.discountType;
    this.billId = null;
    this.providerId = data.providerId;
    this.description = data.description;
    this.vatSerial = data.vatSerial;
    this.vatCode = data.vatCode;
    this.vatDate = data.vatDate;
    this.stockSlipDetails = data.stockSlipDetails;
    this.vatAmount = data.vatAmount;
    this.vatType = data.vatType;
  }
}

export class ProductStockUpdatePayload extends ProductStockPayload {
  constructor(data) {
    super(data);
    this.id = data.id;
  }
}

export class ProductStockDetailPayload {
  /**
   * @param {Object} data
   * @param {String} data.productId
   * @param {Number} data.quantity
   * @param {Number} data.unitPrice
   * @param {Number} data.discountAmount
   * @param {String} data.chassisNumber
   * @param {String} data.engineNumber
   * @param {String} data.serialNumber
   */
  constructor(data) {
    this.productId = data.productId;
    this.quantity = data.quantity;
    this.unitPrice = data.unitPrice;
    this.discountAmount = data.discountAmount;
    this.chassisNumber = data.chassisNumber;
    this.engineNumber = data.engineNumber;
    this.serialNumber = data.serialNumber;
    // tắt discount type để ở API tính
    // this.discountType = data.discountType;
  }
}

export class ProductStockDetailUpdatePayload extends ProductStockDetailPayload {
  constructor(data) {
    super(data);
    this.id = data.id;
  }
}
