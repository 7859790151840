const productStockInfoMixin = {
  methods: {
    openProductStockModal(selectedProductStock, index) {
      this.selectedProductStock = Object.assign({}, selectedProductStock);
      this.$nextTick(() => {
        this.$refs['product-stock-info'].showModal(
          this.selectedProductStock,
          index,
        );
      });
    },
    handleUpdateProductStockInfo(data) {
      this.$set(this.listProductStock, data.index, {
        ...this.listProductStock[data.index],
        ...data,
      });
    },
  },
};

export default productStockInfoMixin;
